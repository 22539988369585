<template>
  <div :class="isOpenPrinter ? 'open-print' : ''">
    <div class="main-page">
      <h2 class="page-title">
        QRコード
      </h2>
      <div class="page-content">
        <div class="list-instructions">
          <ul>
            <li>
              <p class="text-jp">
                以下のQRコードを新宿区役所本庁舎1階のQRコードリーダーにかざして下さい。
                <br>このままの画面、または、スクリーンショットで保存した画像をご提示ください。（スクリーンショットの撮り方は、下の「保存方法」をご参照ください。）
              </p>
            </li>
            <li>
              <p class="text-jp">
                パソコン等から紙に印刷したQRコードを受付へお持ちいただいても結構です。
              </p>
            </li>
            <li
              v-if="isQrError"
              class="attension"
            >
              文字数超過の為QRコードが作成できません。<br>大変申し訳ございませんが窓口にてお手続きをお願い致します。
            </li>
          </ul>
        </div>
        <div class="qr-code-image">
          <div class="img">
            <qrcode-vue
              v-if="encryptedQRString"
              :value="encryptedQRString"
              :size="qrSize"
            />
          </div>
        </div>
        <div class="attention">
          ※QRコードが表示されない場合は大変お手数ですが窓口にてお手続きをお願いします。<br>
          ※QRコードは、㈱デンソーウェーブの登録商標です。
        </div>
        <div class="section-button-list">
          <div
            class="trans btn-how-to-save"
            @click="toggleShowDesc"
          >
            <span class="jp">保存方法</span>
          </div>
        </div>
        <div
          v-show="isShowDesc"
          class="block-text-instruction-print"
        >
          <div class="section-jp">
            <div class="item">
              <p class="description">
                以下の操作でQRコードを画像として保存できます。
                <br>保存された画像は写真フォルダに入ります。
              </p>
            </div>
            <div class="item">
              <h3 class="title ml-8">
                【iPhoneの場合】
              </h3>
              <ul class="description">
                <li>①QRコードが画面中央にくるようスクロールします</li>
                <li>
                  ②電源ボタンとホームボタン（iPhone
                  X以降は音量を上げるボタン）を同時に押します
                </li>
                <li>③シャッター音が鳴ったらボタンを離します</li>
              </ul>
            </div>
            <div class="item">
              <h3 class="title ml-8">
                【Androidの場合】
              </h3>
              <ul class="description">
                <li>①QRコードが画面中央にくるようスクロールします</li>
                <li>②電源ボタンと音量を下げるボタンを同時に長押しします</li>
                <li>③シャッター音が鳴ったらボタンを離します</li>
                <li>※機種により操作が異なる場合があります。</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="list-button">
          <button
            type="button"
            class="button trans"
            @click="back()"
          >
            <span class="txt">トップページ</span>
          </button>
          <button
            type="button"
            class="button trans"
            @click="print()"
          >
            <span class="txt">紙への印刷はこちら</span>
          </button>
        </div>
      </div>
    </div>
    <div class="main-print-layout">
      <p class="row description">
        こちらのQRコードを新宿区役所本庁舎1階の<br>QRコードリーダーにかざして下さい。<br>
        （発行日：{{ today }}）
      </p>
      <p
        v-if="isQrError"
        class="row attension"
      >
        文字数超過の為QRコードが作成できません。<br>大変申し訳ございませんが窓口にてお手続きをお願い致します。
      </p>
      
      <div class="qr-code-image">
        <div class="img">
          <qrcode-vue
            v-if="encryptedQRString"
            :value="encryptedQRString"
            :size="qrSize"
          />
        </div>
      </div>
      <div class="footer-qr-code">
        <!-- アプリケーション固有の設定 -->
        <h2 class="title">
          新宿区役所
        </h2>
        <p class="contact-info">
          〒160-8484 東京都新宿区歌舞伎町1-4-1<br>
          電話：03-5273-3601（直通）<br>
          開庁時間：平日 8時30分～17時00分<br>
          ※毎週火曜日は、19：00まで開庁しています。<br>
          ※毎月第4日曜は、9：00～17：00まで開庁しています。<br>
          土曜日・日曜日・祝日・年末年始<br>
          （12月29日～1月3日）は休みになります
        </p>
        <!-- //アプリケーション固有の設定 -->
      </div>
    </div>
  </div>
</template>
<style>
.attension {
  color: red;
}
</style>
<script>
import GlobalConst from '../Constants.js';
import QrcodeVue from 'qrcode.vue';
export default {
  name: 'Confirm',
  components: {
    QrcodeVue,
  },
  data() {
    return {
      values: [], // Object containing values of all fields
      csvValues: [], // Object containing values of raw CSV
      screenData: {}, // Object containing input values

      QRString: '', // String for QR code
      encryptedQRString: '', // Encrypt string for QR code
      qrSize: GlobalConst.qrSize, // QR code settings

      isShowDesc: false,
      isOpenPrinter: false,
      isQrError: false,
      today: '',
      forms: {}
    };
  },
  computed: {
    formsShow() {
      let arr = [];
      for (let key in this.forms) {
        if (this.forms[key]) arr.push(parseInt(key.slice(6), 10));
      }
      return arr;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.init();
  },
  methods: {
    //
    // PAGE TRANSITION
    //
    /**
     * Change router
     * @param {string} name - name of router to change
     */
    changeRouter: function (name) {
      this.$router.push({ name: name });
    },
    /**
     * Clear all data and go back to form selection screen "selection.vue"
     */
    back: function () {
      window.location.reload();
    },
    /**
     * Show QR Code in browser print settings window.
     * Layout is shown in "プリントレイアウト | PRINT LAYOUT" sheet.
     */
    print: function () {
      var self = this;
      setTimeout(function () {
        window.print();
      }, 100);

      self.isOpenPrinter = true;
      var afterPrint = function () {
        setTimeout(function () {
          self.isOpenPrinter = false;
        }, 1000);
      };

      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener(function (mql) {
          if (mql.matches) {
            // beforePrint();
          } else {
            afterPrint();
          }
        });
      }

      window.onafterprint = afterPrint;
    },



    //
    // UTILITIES
    //
    /**
     * Check correct json or not
     */
    isJsonString: function(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    /**
     * Change isShowDesc to fase/true. It will toggle Screenshot save description in view.
     */
    toggleShowDesc: function () {
      this.isShowDesc = !this.isShowDesc;
    },
    /**
     * Use values to build QR Code string and save it in QRString property.
     */
    createDateString: function (date, type) {
      let newDate;
      if (date) {
        newDate = new Date(date);
      } else {
        newDate = new Date();
      }
      let currentMonth = newDate.getMonth() + 1;
      let currentDate = newDate.getDate();
      currentMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
      currentDate = currentDate < 10 ? '0' + currentDate : currentDate;
      if (type == 1) {
        return newDate.getFullYear() + '' + currentMonth + currentDate;
      } else {
        return (
          newDate.getFullYear() +
          '年' +
          currentMonth +
          '月' +
          currentDate +
          '日'
        );
      }
    },
    /**
     * Change double size of english/kana to half size
     */
    convertDoubleSizeToHalf: function (target) {
      if ( target != '' ) {
        // kana
        let beforeKana = new Array('ァ','ィ','ゥ','ェ','ォ','ャ','ュ','ョ','ッ','ー','ヴ','ガ','ギ','グ','ゲ','ゴ','ザ','ジ','ズ','ゼ','ゾ','ダ','ヂ','ヅ','デ','ド','バ','ビ','ブ','ベ','ボ','パ','ピ','プ','ペ','ポ','ア','イ','ウ','エ','オ','カ','キ','ク','ケ','コ','サ','シ','ス','セ','ソ','タ','チ','ツ','テ','ト','ナ','ニ','ヌ','ネ','ノ','ハ','ヒ','フ','ヘ','ホ','マ','ミ','ム','メ','モ','ヤ','ユ','ヨ','ラ','リ','ル','レ','ロ','ワ','ヲ','ン');
        let afterKana = new Array('ｧ','ｨ','ｩ','ｪ','ｫ','ｬ','ｭ','ｮ','ｯ','ｰ','ｳﾞ','ｶﾞ','ｷﾞ','ｸﾞ','ｹﾞ','ｺﾞ','ｻﾞ','ｼﾞ','ｽﾞ','ｾﾞ','ｿﾞ','ﾀﾞ','ﾁﾞ','ﾂﾞ','ﾃﾞ','ﾄﾞ','ﾊﾞ','ﾋﾞ','ﾌﾞ','ﾍﾞ','ﾎﾞ','ﾊﾟ','ﾋﾟ','ﾌﾟ','ﾍﾟ','ﾎﾟ','ｱ','ｲ','ｳ','ｴ','ｵ','ｶ','ｷ','ｸ','ｹ','ｺ','ｻ','ｼ','ｽ','ｾ','ｿ','ﾀ','ﾁ','ﾂ','ﾃ','ﾄ','ﾅ','ﾆ','ﾇ','ﾈ','ﾉ','ﾊ','ﾋ','ﾌ','ﾍ','ﾎ','ﾏ','ﾐ','ﾑ','ﾒ','ﾓ','ﾔ','ﾕ','ﾖ','ﾗ','ﾘ','ﾙ','ﾚ','ﾛ','ﾜ','ｦ','ﾝ');
        for(let i = 0; i < beforeKana.length; i++) {
          target = target.replace(new RegExp(beforeKana[i], 'g'), afterKana[i]);
        }
        // english
        target = target.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, function(s){
          return String.fromCharCode(s.charCodeAt(0)-0xFEE0);
        });
      }
      return target;
    },
    /*
     * Make output string for QR code
     */
    createQRString: function () {
      let result = {};

      // make hash
      this.csvValues.forEach((item) => {
        item.groupFields.forEach((field) => {
          result[field.noId] = '';
        });
      });

      // pre setup
      // 来庁者もしくは請求者と同じを指定し、且つ
      // A.入力値が同じ場合 => 値を格納しない
      // B.入力値が違う場合 => チェックボックス項目を0にする
      // ※index値は 0 か 1のみの2値を想定
      let emptyFields = [];
      this.values.forEach((item) => {
        item.groupFields.forEach((field) => {
          if(field.type == 'Checkbox' &&
            (
              this.screenData['field_'+field.noId+'_0'] != undefined && this.screenData['field_'+field.noId+'_0'] == true ||
              this.screenData['field_'+field.noId+'_1'] != undefined && this.screenData['field_'+field.noId+'_1'] == true
            )
          ) {
            if(this.isJsonString(field.other.replace(/__/g,','))) {
              let index = (this.screenData['field_'+field.noId+'_0'] == true) ? 0 : 1;
              let copyRules = JSON.parse(field.other.replace(/__/g,','));
              if(copyRules[index] != undefined) {
                let isExactlySame = true;
                
                for(let key in copyRules[index]) {
                  if(copyRules[index][key].indexOf('+') != -1) {
                    let join = '';
                    let ids = copyRules[index][key].split('+');
                    for(let key2 in ids) {
                      join += this.screenData['field_'+ids[key2]];
                    }
                    if(this.screenData['field_'+key] != join)
                      isExactlySame = false;
                  } else {
                    if(this.screenData['field_'+key] != this.screenData['field_'+copyRules[index][key]])
                      isExactlySame = false;
                  }
                }
                if(isExactlySame) {
                  for(let key in copyRules[index]) {
                    emptyFields.push('field_'+key); // 他からの参照が残っているため、ここでは削除しない
                  }
                } else {
                  if(this.screenData['field_'+field.noId+'_0'] != undefined) this.screenData['field_'+field.noId+'_0'] = false;
                  if(this.screenData['field_'+field.noId+'_1'] != undefined) this.screenData['field_'+field.noId+'_1'] = false;
                }
              }
            } else if(field.other.startsWith('Readonly')) {
              // Readonlyの場合は、必然的に値変更が起きないのでExactlySameとする
              let noIds = field.other.split('__');
              noIds.forEach(noId => emptyFields.push('field_'+noId));
            }
          }
        });
      });
      for(let key in emptyFields) { // ループ後に削除する
        this.screenData[emptyFields[key]] = '';
      }

      // form values
      this.values.forEach((item) => {
        item.groupFields.forEach((field) => {
          let tempData = '';
          // single vector value
          if (this.screenData['field_' + field.noId]) {
            // date format
            if (
              field.format == 'YYYY年M月D日' ||
              field.format == 'YYYY年M月D日（Future）'
            ) {
              tempData = this.createDateString(
                this.screenData['field_' + field.noId],
                1
              );
            } else if(field.format == 'YYYY（元号YY）') {
              // 和暦削除
              tempData = this.screenData['field_' + field.noId].split(' ')[0];
            } else if(field.format == 'Text（Half）') {
              // 半角処理
              tempData = this.convertDoubleSizeToHalf(this.screenData['field_' + field.noId]);
            } else if(field.format == 'ID') {
              // ID選択型
              tempData = this.screenData['field_' + field.noId].split('．')[0];
            } else {
              tempData = this.screenData['field_' + field.noId];
            }
          // other types
          } else {
            if (field.format == 'Text（Number input）'
             && this.screenData['field_' + field.noId + '_0']
             && this.screenData['field_' + field.noId + '_1']
             && this.screenData['field_' + field.noId + '_2']
             ) {
              // phone number
              tempData += this.screenData['field_' + field.noId + '_0'] + '-';
              tempData += this.screenData['field_' + field.noId + '_1'] + '-';
              tempData += this.screenData['field_' + field.noId + '_2'];
            }
            if (field.type == 'Checkbox') {
              field.desc.forEach((descItem, descIndex) => {
                if (this.screenData['field_' + field.noId + '_' + descIndex] == true) {
                  if(field.format == 'ID') tempData = ''+(parseInt(descIndex,10)+1); // ID選択型
                  else tempData += (parseInt(descIndex,10)+1) + '/';
                }
              });
              if (tempData.indexOf('/') > -1) {
                tempData = tempData.slice(0, -1); // remove last character '/'
              } else if(tempData == '') {
                tempData = '0';
              }
            }
          }
          result[field.noId] = tempData;
        });
      });
      var csvResult = new Array;
      // version
      csvResult.push(GlobalConst.qrHeadVersion);
      // selection
      csvResult.push(this.formsShow.join('/'));
      // form values
      this.csvValues.forEach((item) => {
        item.groupFields.forEach((field) => {
          csvResult.push(result[field.noId]);
        });
      });
      // show error if longer than threshold
      if(csvResult.join(',').length >= GlobalConst.qrErrorLength) this.isQrError = true;
      else {
        this.isQrError = false;
        this.QRString = csvResult.join(',');
        if(process.env.NODE_ENV == 'development') {
          console.log('[data]');
          console.log(this.QRString);
        }
        this.encryptQRStringByCeaser();
      }
    },
    /**
     * Convert string to byte array
     */
    convertStringToArray: function(str) {
      var array = [],i,il=str.length;
      for(i=0;i<il;i++) array.push(str.charCodeAt(i));
      return array;
    },
    /**
     * Shift charcode forward/back by UTF8
     */
    shiftCharcodeForUTF8: function(arr,keyArr,isForward) {
      /*
       * ・バイト数判定
       * 1byte --> 0*** **** --> >> 7 == 0
       * 2byte --> 110* **** --> >> 5 == 6
       * 3byte --> 1110 **** --> >> 4 == 14
       * 4byte --> 1111 **** --> >> 4 == 15
       *
       * ・バイト数別値範囲
       * https://ja.wikipedia.org/wiki/UTF-8
       */
      let i = 0;
      let keyPos = 0;
      let key = 0;
      for(i = 0;i < arr.length;i++) {
        key = keyArr[keyPos];
        if((keyPos + 1) < keyArr.length) keyPos++;
        else keyPos = 0;
        
        let byteLength = arr[i] >> 7 == 0 ? 1 : (arr[i] >> 5 == 6 ? 2 : (arr[i] >> 4 == 14 ? 3 : (arr[i] >> 4 == 15 ? 4 : 1)));
        if(isForward) {
          switch(byteLength) {
            case 1:
              arr[i] += key;
              break;
            case 2:
              if( (arr[i+1] + key) > 0xBF) {
                arr[i+1] = arr[i+1] + key - 0xBF + 0x80;
                arr[i]++;
              } else {
                arr[i+1] += key;
              }
              i += 1;
              break;
            case 3:
              if( (arr[i+2] + key) > 0xBF) {
                if( (arr[i+1] + 1) > 0xBF) {
                  arr[i+2] = arr[i+2] + key - 0xBF + 0x80;
                  arr[i+1] = 0x80;
                  arr[i]++;
                } else {
                  arr[i+2] = arr[i+2] + key - 0xBF + 0x80;
                  arr[i+1]++;
                }
              } else {
                arr[i+2] += key;
              }
              i += 2;
              break;
            case 4:
              if( (arr[i+3] + key) > 0xBF) {
                if( (arr[i+2] + 1) > 0xBF) {
                  if( (arr[i+1] + 1) > 0xBF) {
                    arr[i+3] = arr[i+3] + key - 0xBF + 0x80;
                    arr[i+2] = 0x80;
                    arr[i+1] = 0x80;
                    arr[i]++;
                  } else {
                    arr[i+3] = arr[i+3] + key - 0xBF + 0x80;
                    arr[i+2] = 0x80;
                    arr[i+1]++;
                  }
                } else {
                  arr[i+3] = arr[i+3] + key - 0xBF + 0x80;
                  arr[i+2]++;
                }
              } else {
                arr[i+3] += key;
              }
              i += 3;
              break;
          }
        } else {
          switch(byteLength) {
            case 1:
              arr[i] -= key;
              break;
            case 2:
              if( (arr[i+1] - key) < 0x80) {
                arr[i+1] = 0xBF - (0x80 - (arr[i+1] - key));
                arr[i]--;
              } else {
                arr[i+1] -= key;
              }
              i += 1;
              break;
            case 3:
              if( (arr[i+2] - key) < 0x80) {
                if( (arr[i+1] - 1) < 0x80) {
                  arr[i+2] = 0xBF - (0x80 - (arr[i+2] - key));
                  arr[i+1] = 0xBF;
                  arr[i]--;
                } else {
                  arr[i+2] = 0xBF - (0x80 - (arr[i+2] - key));
                  arr[i+1]--;
                }
              } else {
                arr[i+2] -= key;
              }
              i += 2;
              break;
            case 4:
              if( (arr[i+3] - key) < 0x80) {
                if( (arr[i+2] - 1) < 0x80) {
                  if( (arr[i+1] - 1) < 0x80) {
                    arr[i+3] = 0xBF - (0x80 - (arr[i+3] - key));
                    arr[i+2] = 0xBF;
                    arr[i+1] = 0xBF;
                    arr[i]--;
                  } else {
                    arr[i+3] = 0xBF - (0x80 - (arr[i+3] - key));
                    arr[i+2] = 0xBF;
                    arr[i+1]--;
                  }
                } else {
                  arr[i+3] = 0xBF - (0x80 - (arr[i+3] - key));
                  arr[i+2]--;
                }
              } else {
                arr[i+3] -= key;
              }
              i += 3;
              break;
          }
        }
      }
      return arr;
    },
    /**
     * Use Ceaser encrypt algorithm to encrpyt QRString, and save result to encryptedQRString property.
     * This string will be used automatically to generate QR Code in view.
     */
    encryptQRStringByCeaser: async function () {
      let response = await this.$http.get('./key.json');
      let secretKey = response.body.secret;
      
      // convert by ceaser encrypt
      let utf8Array = this.convertStringToArray(this.QRString);
      utf8Array = this.shiftCharcodeForUTF8(utf8Array,secretKey,true);
      this.encryptedQRString = window.EncodingJapanese.codeToString(utf8Array);

      if(process.env.NODE_ENV == 'development') {
        console.log('[qr]');
        console.log(this.encryptedQRString);
      }
    },



    //
    // INITIALIZATION
    //
    init: function () {
      this.forms = this.$store.state.selection;
      this.values = this.$store.state.values;
      this.csvValues = this.$store.state.csvValues;
      this.screenData = this.$store.state.screenData;
      this.today = this.createDateString(null, 2);
      this.createQRString();
    },
  },
};
</script>