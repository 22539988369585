const GlobalConst = {
  // qrcode settings
  qrHeadVersion: '0001',
  qrSize: 262,
  qrErrorLength: 900,
  
  // init forms
  initForms: {
    select1: true,
    select2: false,
    select3: false,
  },
  
  // page titles
  titles: [
    {
      id: 1,
      name: '申請内容入力',
      leadText: '以下を入力してください',
    },
    {
      id: 2,
      name: '住民異動届',
      leadText: '以下を入力してください',
    },
    {
      id: 3,
      name: '住民票等の請求書',
      leadText: '以下を入力してください',
    },
  ]
};

export default GlobalConst;