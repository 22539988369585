<template>
  <div id="app">
    <div class="all-page-content">
      <Header />
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: {
    Header,
  },
  methods: {},
};
</script>
