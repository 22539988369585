import './assets/sass/style.sass';
import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';

import GlobalConst from './Constants.js';
import selection from './views/selection';
import agreements from './views/agreements';
import formLayout from './views/form';
import qrCode from './views/qr-code';
import VueResource from 'vue-resource';
Vue.use(VueResource);

window.YubinBango = require('yubinbango-core');
window.EncodingJapanese = require('encoding-japanese');

Vue.config.productionTip = false;
Vue.directive('click-outside', {
  bind: function (el, binding) {
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble;
    const handler = e => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    el.vueClickOut = handler;
    // add Event Listeners
    document.addEventListener('click', handler);
  },
  unbind: function (el) {
    // Remove Event Listeners
    document.removeEventListener('click', el.vueClickOut);
    el.vueClickOut = null;
  }
});
Vue.use(Vuex);
Vue.use(VueRouter);

const store = new Vuex.Store({
  state: {
    selection: GlobalConst.initForms,
    values: [],
    csvValues: [],
    screenData: {}
  }
});

const router = new VueRouter({
  routes: [
    { path: '/', name: 'selection', component: selection },
    { path: '/', name: 'agreements', component: agreements },
    { path: '/', name: 'form', component: formLayout },
    { path: '/', name: 'qr-code', component: qrCode },
  ]
})

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')
