<template>
  <div class="main-page">
    <h1 class="page-title">
      同意事項
    </h1>
    <div class="page-content">
      <ul class="list-instructions">
        <li>
          <p class="text-jp">
            以下の利用規約をお読みいただき、内容に同意いただけましたら「同意して次へ」を押してください。
          </p>
        </li>
      </ul>
      <section class="section-category">
        <h2 class="title-item green">
          <span class="jp">同意事項</span>
        </h2>
        <!-- アプリケーション固有の設定 -->
        <div class="content">
          <div
            id="kiyaku"
            class="kiyaku"
          >
            <center>スマホ申請書作成サービス利用規約</center>  
            １　利用規約の適用<br>
            この利用規約は、新宿区（以下「当区」という。）が提供する、スマートフォン等を利用して各種申請書を作成するサービス（以下「スマホ申請書作成サービス」という。）を利用する際における必要事項を定めるものです。本規約は、本サービスを利用する全ての方に適用されます。<br>
            ２　定義<br>
            本規約で使用する用語の定義は以下のとおりです。<br>
            (1) 「利用者」とは、本サービスを利用して各種申請書等の作成を行う者をいいます。<br>
            (2) 「スマートフォン等」とは、本サービスを利用するために使用するスマートフォンまたはパソコン等をいいます。<br>
            (3) 「申請等」とは、本サービスを利用して手続きを行う申請または届出をいいます。<br>
            (4) 「申請書等」とは、本サービスを利用して作成する申請書または届書をいいます。<br>
            ３　サービスを利用できる者<br>
            (1) このサービスは、新宿区地域振興部戸籍住民課（新宿区役所本庁舎１階）の窓口で申請等を行おうとする方を対象に提供します。区内10か所の特別出張所の窓口で申請等を行おうとする方は利用できません。<br>
            (2) このサービスを利用できる方は、申請等を行う本人、本人の法定代理人または本人から委任を受けた任意代理人とします。<br>
            (3) 申請等を行おうとする方が以下の方は利用できません。<br>
            ア　国または地方公共団体の機関<br>
            イ　特定事務受任者（弁護士、司法書士、土地家屋調査士、税理士、社会保険労務士、弁理士、海事代理士、行政書士）<br>
            ウ　法人<br>
            ４　サービスの内容<br>
            このサービスの利用手順は以下のとおりです。<br>
            (1) 当区が指定するホームページに、利用者が自ら利用者の所有するスマートフォン等を用いてアクセスします。<br>
            (2) 利用規約に同意のうえ、利用者が作成しようとする申請書等を選択します。<br>
            (3) 利用者が自ら、申請書等の情報をスマートフォン等に入力します。<br>
            (4) 利用者のスマートフォン等の内部で申請書等の情報を記録したＱＲコードを作成し、当該<br>
            ＱＲコードをスマートフォン等の内部に保存、または紙に印刷します。<br>
            (5) 新宿区役所本庁舎に来庁し、１階ロビーに設置されたＱＲコードスキャナ、パソコン及びプリンタを利用者が自ら操作し、事前にスマートフォン等の内部に保存、または紙に印刷したＱＲコードを読み込むことにより申請書等を印刷します。<br>
            ５　申請書等の情報の取扱い<br>
            (1) スマートフォン等に入力した申請書等の情報は、スマートフォン等の内部でのみ処理され、インターネット等の外部には一切送信されません。また、申請書等の情報は、スマートフォン等の内部でＱＲコードを作成した後に、当該ＱＲコードの表示画面を閉じた際に自動消去されます。<br>
            (2) 新宿区役所本庁舎１階に設置されたパソコンで読み込んだＱＲコード及び申請書等の情報は、申請書等の印刷が完了した後に自動消去されます。<br>
            ６　サービス利用上の注意<br>
            (1) 利用者は、自己の責任のもとで本サービスを利用するものとします。<br>
            (2) スマートフォン等の内部で作成したＱＲコードには個人情報を含みます。当該ＱＲコードの管理は利用者自らの責任において行うものとします。<br>
            (3) このサービスは、当区の申請書等を作成する目的以外には使用できません。<br>
            ７　民間事業者が提供するサービスの利用<br>
            このサービスは、当区と協定を締結している民間事業者が提供するサービスを利用しています。当該事業者は利用者の個人情報は一切取り扱いません。<br>
            ８　免責事項<br>
            本サービスの利用に際して発生した損害については、当区の故意または重過失がない限り責任を負いません。<br>
            ９　その他<br>
            (1) 本規約は、利用者の承諾を得ることなく変更することがあります。<br>
            (2) 本規約には日本法が適用されるものとします。本サービスの利用に関連して当区と利用者間に生じる全ての訴訟については、東京地方裁判所を第一審の専属的合意管轄裁判所として定めます。<br>
            <br>
            <p style="text-align: right">令和３年１１月１日　新宿区</p>
          </div>
          <div class="agreements-list">
            <!-- 3 -->
            <p v-if="forms.select3">
              《住民票について》<br>
              ■住民票の写し等の交付を請求できる場合は次のとおりです。<br>
              (1)　本人または本人と同一世帯に属する方による請求 <br>
              (2)　国・地方自治体の機関による請求 <br>
              (3)　その他正当な理由がある方による請求 （自己の権利の行使や義務の履行に必要な場合など） <br>
              <br>
              ■請求の際は、窓口に来た方の本人確認書類をご提示いただきます。 <br>
              ■代理人が請求する場合は、委任状が必要です。 <br>
              ■第三者が請求する場合は、「請求理由を明らかにする資料」が必要です。 <br>
              ■偽りその他不正な手段により、住民票の写し等の交付を受けた場合は、30万円以下の罰金に処せられます。（住民基本台帳法第46条第2号）
            </p>
            <p>
              <!-- always -->
              《その他留意事項》<br>
              ■同一のお手続きで複数のご請求がある場合は、お手数ですがトップ画面から再度入力をお願いします。<br>
            </p>
          </div>
        </div>
        <!-- // アプリケーション固有の設定 -->
      </section>
      <div class="list-button">
        <button
          type="button"
          class="button trans"
          @click="changeRouter('selection')"
        >
          <span class="txt">戻る</span>
        </button>
        <button
          type="button"
          class="button trans"
          :disabled="isNotRead"
          @click="changeRouter('form')"
        >
          <span class="txt">同意して次へ</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agreements',
  data() {
    return {
      isNotRead: true,
      forms: {}
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.forms = this.$store.state.selection;
    document.getElementById('kiyaku').addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    // Any code to be executed when the window is scrolled
    handleScroll: function (event) {
      if(document.getElementById('kiyaku').scrollHeight*0.80 < event.target.scrollTop) {
        this.isNotRead = false;
      }
    },
    /**
     * Change router
     * @param {string} name - name of router to change
     */
    changeRouter: function (name) {
      this.$router.push({ name: name });
    },
  },
};
</script>

<style scoped>
.kiyaku {
  max-height: 12rem;
  overflow-y: scroll;
  border: 1px solid gray;
  padding: 1em;
  font-size: 14px;
  margin: 1em 0;
  line-height: 2em;
}
.button:disabled {
  cursor: unset;
  background-color: gainsboro;
  color: black;
}
</style>
